import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { API_URL } from './app-config';
import { Login_URL } from './app-config';
import Swal from 'sweetalert2';
import { Observable } from 'rxjs';
// import 'rxjs/add/operator/map';

export class Population {
  arg: number;

  val: number;
}

@Injectable()
export class AuthService {

  private _registerUrl = Login_URL + '/users/register';
  private _loginUrl = Login_URL + '/auth/login';
  private _menuUrl = API_URL + 'menu';
  private visitortype = API_URL;


  constructor(private http: HttpClient, private _router: Router) { }

  registerUser(user) {
    return this.http.post<any>(this._registerUrl, user);
  }

  loginUser(user) {
    return this.http.post<any>(this._loginUrl, user);
  }

  // postvisitortype(data): Observable<any> {
  //   return this.http.post(`https://pure-shelf-20106.herokuapp.com/visitorTypes`, data);
  // }

  postvisitortype(data) {
    return this.http.post<any>(`https://polar-falls-03429.herokuapp.com/song`, data);
  }

  logoutUser() {
    Swal.showLoading();
    localStorage.clear();
    Swal.close();
    this._router.navigate(['pages/login']);
  }


  getToken() {
    // console.log(localStorage.getItem('token'))
    return localStorage.getItem('token') || '';
  }

  getuserid() {
    // console.log(localStorage.getItem('token'))
    return localStorage.getItem('userid') || '';
  }

  getRole() {
    // console.log(localStorage.getItem('token'))
    return localStorage.getItem('role') || '';
  }



  getUserInfo() {
    return localStorage.getItem('userinfo') || '';
  }

  getUserPhone() {
    return localStorage.getItem('userphone') || '';
  }

  getUserPhoto() {
    return localStorage.getItem('userphoto') || '';
  }

  loggedIn() {
    return !!localStorage.getItem('token');
  }
}
