import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from './auth.service';


@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private auth: AuthService, private _router: Router) { }

  canActivate(): boolean {
    console.log(this.auth.loggedIn());
    if (this.auth.loggedIn()) {
      return true;
    }
    else {
      this._router.navigate(['/pages/login']);
      return false;
    }
  }
}
