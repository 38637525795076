import { RouteInfo } from './vertical-menu.metadata';

//Sidebar menu Routes and data
export const ROUTES: RouteInfo[] = [

  { path: '/business', title: 'All Business', icon: 'fa fa-list-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  { path: '/pending-payment', title: 'Pending Payment', icon: 'fa fa-money', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  { path: '/processing', title: 'Processing', icon: 'fa fa-spinner', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  { path: '/in-progress', title: 'In Progress', icon: 'fa fa-spinner', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  { path: '/qaulitycontrol', title: 'Quality Control', icon: 'fa fa-tasks', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  { path: '/production', title: 'Production', icon: 'fa fa-id-card', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  { path: '/completed', title: 'Completed', icon: 'fa fa-id-card', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
 
  // {
  //   path: '', title: 'Business', icon: 'fa fa-building-o', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, 
  //   submenu: [
  //     { path: '/business', title: 'All Business', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  //     { path: '/pending-payment', title: 'Pending Payment', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  //     { path: '/processing', title: 'Processing', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  //     { path: '/qaulitycontrol', title: 'Quality Control', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  //     { path: '/production', title: 'Production', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  //   ]
  // },
  {
    path: '', title: 'Users', icon: 'fa fa-users', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, 
    submenu: [
      { path: '/users', title: 'All Users', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    ]
  },
  {
    path: '/dashboard', title: 'Report', icon: 'ft-home', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  },
  
];
