import { API_URL } from '../shared/services/app-config';

export let allUrl = {
    business: {
        add: API_URL + 'business',
        list: API_URL + 'business',
        search: API_URL + 'business/search',
        getone: API_URL + 'business/',
        edit: API_URL + 'business/',
        delete: API_URL + 'business/',

    },
    plan: {
        add: API_URL + 'plan',
        list: API_URL + 'plan',
    },
    gateway: {
        add: API_URL + 'gateway',
        list: API_URL + 'gateway',
        getone: API_URL + 'gateway/',
        edit: API_URL + 'gateway/',
        delete: API_URL + 'gateway/',
    },
    notification: {
        add: API_URL + 'notification',
        list: API_URL + 'notification',
        getone: API_URL + 'notification/',
        search: API_URL + 'notification/search',
        edit: API_URL + 'notification/',
        delete: API_URL + 'notification/',
    },
    users: {
        add: API_URL + 'users',
        list: API_URL + 'users',
        getone: API_URL + 'users/',
        edit: API_URL + 'users/',
        editmine: API_URL + 'profile',
    },
    upload: {
        post: 'https://imgapi.spanglermarket.com/api-file-upload.php'
    }
    

}